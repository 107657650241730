export default {
  dashboard: 'Dashboard',
  dashboardfv: 'Dashboard',
  clientDashboard: 'Dashboard Cliente',
  // introduction: 'Introduction',
  i18n: 'i18n',
  theme: 'Tema',
  AsignarPOS: 'POS Asigns',
  PosEdit: 'POS',
  PosDatos: 'POS Datos',
  PosEditxUsuario: 'POS x Usuario',
  PosTipo: 'Tipos de POS',
  PosEstado: 'Estado de POS',
  PosMonitor: 'Monitor de POS',
  VendingPagos: 'Cancelar Pagos',
  Usuarios: 'Usuarios',
  UsuarioPOS: 'Asignar POS',
  UsuarioPerfil: 'Perfiles de Usuario',
  Seguridad: 'Seguridad',
  Clientes: 'Clientes',
  POS: 'POS (P. of Sale)',
  PosFirmware: 'Firmware',
  Variables: 'Variables',
  Reportes: 'Reportes',
  RptPOSEventos: 'Contadores POS',
  RptPagosPOS: 'Pagos por POS',
  RptPOSComunicacion: 'Audit. Comunic. POS',
  RptResultadoxPos: 'Producido x POS',
  RptResultadoxPosxDia: 'Producido x POS x Día',
  RptPOSAlertas: 'Alertas x POS',
  RptLogSistema: 'Log Sistema',
  // pagePermission: 'Page Permission',
  // rolePermission: 'Role Permission',
  permissions: 'Permission',
  permission: {
    admin: 'Admin',
    editor: 'Editor',
    visitor: 'Visitor'
  },
  components: 'Components',
  component: {
    avatarUpload: 'Avatar Upload',
    backToTop: 'Back To Top',
    countTo: 'Count To',
    dragKanban: 'Drag Kanban',
    dropzone: 'Dropzone',
    jsonEditor: 'Json Editor',
    markdown: 'Markdown',
    splitPane: 'Split Pane',
    sticky: 'Sticky',
    tinymce: 'Tinymce'
  },
  vuetify: 'Vuetify UI',
  vuetifyComponents: {
    components: 'Components',
    alert: 'Alert',
    avatar: 'Avatars',
    badge: 'Badges',
    buttons: 'Buttons',
    calendar: 'Calendar',
    cards: 'Cards',
    carousels: 'Carousels',
    chip: 'Chip',
    colors: 'Colors',
    datepicker: 'Datepicker',
    dialogs: 'Dialogs',
    grid: 'Grid',
    icon: 'Icons',
    pagination: 'Pagination',
    parallax: 'Parallax',
    progress: 'Progress',
    slider: 'Slider',
    snackbar: 'Snackbar',
    tables: 'Tables',
    timepicker: 'Timepicker',
    tooltip: 'Tooltip',
    typography: 'Typography'
  },
  errors: 'Errors',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500'
  },
  charts: 'Charts',
  chart: {
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart'
  },
  nested: {
    nested: 'Nested',
    nested1: 'Level 1',
    nested2: 'Level 2',
    nested3: 'Level 3'
  },
  clipboard: 'Clipboard',
  externalLink: 'External Link'
}
