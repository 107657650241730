<template>
  <div>
    <v-form
      ref="form"
      v-model="validForm"
      lazy-validation
    >
      <v-card>
        <v-card-title class="white--text align-end primary">
          <span>Mensaje MQTT</span>
        </v-card-title>
        <v-card-text class="text-h6">
          <v-container>
            <v-row dense>
              <v-col
                cols="9"
                sm="9"
              >
                <cb-pos
                  v-model="activeRow.idPos"
                  :disabled="disabled"
                />
              </v-col>
              <v-col
                cols="3"
                sm="3"
              >
                <cas-text-box
                  v-model="commandCode"
                  label="Command Code"
                  :disabled="disabled"
                  counter="5"
                  prepend-icon="mdi-hexadecimal"
                  hint="Hex byte like 0x01"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                v-for="(param, index) in parameters"
                :key="index"
                cols="12"
                sm="12"
              >
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-input
                      :label="'Parameter ' + (index + 1)"
                      :disabled="disabled"
                    >
                      <v-row dense>
                        <v-col
                          cols="8"
                          sm="8"
                        >
                          <cas-text-box
                            v-model="param.value"
                            :label="`'Value  ${index + 1}`"
                            :disabled="disabled"
                            counter="50"
                            prepend-icon="mdi-variable"
                          />
                        </v-col>
                        <v-col
                          cols="4"
                          sm="4"
                        >
                          <!-- Parameter type combo -->
                          <v-select
                            v-model="param.type"
                            :items="types"
                            :label="`'Type  ${index + 1}`"
                            :disabled="disabled"
                            prepend-icon="mdi-memory"
                            :hint="`${types.find(({value}) => parameters[index].type === value).hint}`"
                            persistent-hint
                            append-outer-icon="mdi-delete"
                            @click:append-outer="removeParameter(index)"
                          />
                        </v-col>
                      </v-row>
                    </v-input>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-btn
                  block
                  color="secondary"
                  :disabled="disabled"
                  @click="addParameter"
                >
                  Agregar Parámetro
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!showGuardando"
            @click="close"
          >
            Cancelar
            <v-icon right>
              mdi-close-circle
            </v-icon>
          </v-btn>
          <v-btn
            v-if="showGuardar"
            class="primary"
            @click="guardar"
          >
            Guardar
            <v-icon right>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-progress-circular
            v-if="showGuardando"
            indeterminate
            color="green"
          />
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog
      v-model="showResponse"
      width="800px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title class="white--text align-end primary">
          <span>Respuesta MQTT</span>
        </v-card-title>
        <v-card-text class="text-h6">
          <v-container>
            <v-row dense>
              <v-col
                cols="12"
                sm="12"
              >
                <cas-text-box
                  v-model="generatedMessage"
                  label="Mensaje Recibido"
                  :disabled="true"
                  prepend-icon="mdi-hexadecimal"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="closeResponse"
          >
            Cerrar
            <v-icon right>
              mdi-close-circle
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import casTextBox from '@/components/casTextBox'
import cbPos from '@/components/cbPos'
const defaultParameter = {
  value: 0,
  type: 'uint8'
}

export default {
  components: {
    casTextBox,
    cbPos
  },
  data () {
    return {
      // showGuardando: false,
      // showGuardar: true,
      commandCode: '',
      parameters: [{ ...defaultParameter }],
      validForm: true,
      requiredRules: [
        (v) => !!v || 'el valor es obligatorio'
      ],
      // list of parameter types (from nodejs Buffer class)
      types: [
        {
          text: 'UInt8 - 1 byte',
          value: 'uint8',
          hint: 'Unsigned (0 to 255)'
        },
        {
          text: 'UInt16LE - 2 bytes',
          value: 'uint16le',
          hint: 'Unsigned - Least significant byte first'
        },
        {
          text: 'UInt32LE - 4 bytes',
          value: 'uint32le',
          hint: 'Unsigned - Least significant byte first'
        },
        {
          text: 'Int8 - 1 byte',
          value: 'int8',
          hint: 'Signed (-128 to 127)'
        },
        {
          text: 'Int16LE - 2 bytes',
          value: 'int16le',
          hint: 'Signed - Least significant byte first'
        },
        {
          text: 'Int32LE - 4 bytes',
          value: 'int32le',
          hint: 'Signed - Least significant byte first'
        },
        {
          text: 'FloatLE - 4 bytes',
          value: 'floatle',
          hint: 'Single-precision float. Least significant byte first'
        },
        {
          text: 'DoubleLE - 8 bytes',
          value: 'doublele',
          hint: 'Double-precision float. Least significant byte first'
        },
        {
          text: 'String - variable length',
          value: 'string',
          hint: 'ASCII string'
        },
        {
          text: 'Char - 1 byte',
          value: 'char',
          hint: 'ASCII character'
        },
        {
          text: 'Hex - variable length',
          value: 'hex',
          hint: 'Hexadecimal string like 0x1234'
        }
      ],
      activeRow: {},
      disabled: false
    }
  },
  computed: {
    showGuardando () {
      return this.$store.getters['MensajeMQTT/getShowGuardando']
    },
    showGuardar () {
      return this.$store.getters['MensajeMQTT/getShowGuardar']
    },
    showResponse () {
      return this.$store.getters['MensajeMQTT/getShowResponse']
    },
    generatedMessage () {
      return this.$store.state.MensajeMQTT.response
    }
  },
  methods: {
    addParameter () {
      this.parameters.push({ ...defaultParameter })
    },
    removeParameter (index) {
      this.parameters.splice(index, 1)
    },
    close () {
      this.$store.dispatch('MensajeMQTT/Hide')
    },
    async guardar () {
      const params = {
        idPos: this.activeRow.idPos,
        commandCode: this.commandCode,
        parameters: this.parameters
      }
      if (this.$refs.form.validate())
        this.$store.dispatch('MensajeMQTT/SendMessage', params)
      else
        this.$store.dispatch('Notify/changeShowNotify', {
          colorNotify: 'error',
          textNotify: 'Los valores ingresados no se corresponden con los formatos esperados'
        })
    },
    closeResponse () {
      this.$store.dispatch('MensajeMQTT/ShowResponse', false)
    }
  }
}
</script>
