import { loginByEmail, getUserInfo } from '@/api/login'

const superAdminList = ['norman', 'Admin']
const user = {
  state: {
    token: '',
    roles: [],
    user: '',
    name: '',
    avatar: '',
    status: '',
    code: '',
    introduction: '',
    idCliente: null,
    clientFilter: null,
    EsAdministrador: null,
    isSuperAdmin: false,
    setting: {
      articlePlatform: []
    },
    idUsuario: null,
    Cliente: null,
    EMail: null
  },

  getters: {
    token: (state) => state.token,
    roles: (state) => state.roles,
    name: (state) => state.name,
    user: (state) => state.user,
    avatar: (state) => state.avatar,
    status: (state) => state.status,
    introduction: (state) => state.introduction,
    setting: (state) => state.setting,
    GetidCliente: (state) => state.clientFilter || state.idCliente,
    // GetClientFilter: (state) => state.clientFilter ||,
    GetEsAdministrador: (state) => (state.EsAdministrador === 1),
    GetidUsuario: (state) => state.idUsuario,
    GetIsSuperAdmin: (state) => state.isSuperAdmin

  },

  mutations: {
    SET_USER_INFO: (state, payload) => {
      if (payload.logout) {
        state.token = ''
        state.roles = []
        state.user = ''
        state.name = ''
        state.avatar = ''
        state.code = ''
        state.idCliente = null
        state.clientFilter = null
        state.EsAdministrador = null
        state.isSuperAdmin = false
        state.idUsuario = null
        state.Cliente = null
        state.EMail = null
      } else {
        state.token = payload.token || state.token
        state.roles = payload.roles || state.roles // 2023
        state.user = payload.user || state.user
        state.name = payload.name || state.name
        state.avatar = payload.avatar || state.avatar
        state.code = payload.code || state.code
        state.idCliente = payload.idCliente || state.idCliente
        state.clientFilter = null
        state.EsAdministrador = payload.EsAdministrador || state.EsAdministrador
        state.idUsuario = payload.idUsuario || state.idUsuario
        state.isSuperAdmin = superAdminList.includes(state.user)
        state.Cliente = payload.Cliente || state.Cliente
        state.EMail = payload.EMail || state.EMail

        // Lo convierto a una array, norman 2020
        // const rolesArray = payload.roles || state.roles
        // state.roles = rolesArray.split(',')
      }
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_CLIENT_FILTER: (state, clientFilter) => {
      state.clientFilter = clientFilter
    }
  },

  actions: {
    // Login user
    loginByEmail: async ({ commit, dispatch }, payload) => {
      try {
        const response = await loginByEmail(payload.email.trim(), payload.password)
        // console.log('[vuex.user] LoginByEmail', payload, response)
        await commit('SET_TOKEN', response.data.token)
        await commit('SET_USER_INFO', response.data.user)
        await dispatch('GenerateRoutes', response.data.user)

        // agrego norman
        return response.data.token
      } catch (err) {
        // console.warn('[vuex.user] LoginByEmail', err)
        return null
      }
    },

    GetUserInfo: async ({ commit, state }) => {
      // console.log('[vuex.user] GetUserInfo')
      try {
        const response = await getUserInfo(state.token)

        // Since mockjs does not support custom status codes, it can only be hacked like this
        if (!response)
          throw new Error('Verification failed, please login again.')

        // Verify returned roles are a non-null array
        if (response.user.roles && response.user.roles.length === 0)
          throw new Error('getInfo: roles must be a non-null array!')

        commit('SET_USER_INFO', response.user)
      } catch (err) {
        console.warn('[vuex.user] GetUserInfo', err)
      }
    },

    LogOut: async ({ commit }) => {
      try {
        // console.log('[vuex.user] LogOut')
        await commit('SET_USER_INFO', { logout: true })
      } catch (err) {
        console.warn('[vuex.user] LogOut', err)
      }
    },
    setClientFilter: async ({ commit }, clientFilter) => {
      try {
        // console.log('[vuex.user] setClientFilter', clientFilter)
        await commit('SET_CLIENT_FILTER', clientFilter)
      } catch (err) {
        console.warn('[vuex.user] setClientFilter', err)
      }
    }

    // Dynamically modify permissions
    /* ChangeRoles: async ({ commit, dispatch }, role) => {
      try {
        console.log('[vuex.user] ChangeRoles', role);
        await commit('SET_TOKEN', role);
        const data = await getUserInfo(role);
        await commit('SET_USER_INFO', data);
        // Redraw the side menu after dynamically modifying the permissions
        await dispatch('GenerateRoutes', data);
      } catch (err) {
        console.warn('[vuex.user] ChangeRoles', err);
      }
    }, */
  }
}

export default user
