/** When your routing table is too long, you can split it into small modules */

import Layout from '@/views/layout/TheLayout.vue'

const seguridadRouter = {
  path: '/DashBoard',
  component: Layout,
  redirect: '/Cliente',
  name: 'Dashboard',
  meta: {
    title: 'route.dashboardfv',
    icon: 'mdi-account-lock'
  },
  children: [
    {
      path: '/Cliente',
      component: () => import('@/views/dashboard/DashboardClient.vue'),
      name: 'DashboardCliente',
      // eslint-disable-next-line object-curly-newline
      meta: { title: 'route.clientDashboard', icon: 'mdi-translate', roles: ['admin'], noCache: true }
    }
  ]
}

export default seguridadRouter
