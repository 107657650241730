import axios from 'axios'

export default {
  ListCombo () {
    return new Promise((resolve, reject) => {
      const p = axios.get('/api/pos/ListCombo')

      p.then(({ data }) => {
        sessionStorage.setItem('pos_itemscombo', JSON.stringify(data.recordset))
        resolve(data.recordset)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  List (params) {
    return axios.get('/api/pos/List', { params })
  },
  DatosList (params) {
    return axios.get('/api/pos/DatosList', { params })
  },
  Agregar (params) {
    return axios.post('/api/pos', params)
  },
  Modificar (params) {
    return axios.put('/api/pos', params)
  },
  Borrar (params) {
    return axios.delete('/api/pos', { params })
  },
  UpdatePos (params) {
    return axios.put('/api/pos/UpdatePos', params)
  },
  StatusPos (params) {
    return axios.get('/api/pos/StatusPos', { params })
  },
  DatosConfPos (params) {
    return axios.get('/api/pos/DatosConfPos', { params })
  },
  ActualizarDatos (params) {
    return axios.put('/api/pos/DatosConfPos', params)
  },
  MPCaja (params) {
    return axios.post('/api/mercadopago/caja', params)
  },
  ListxUsuario (params) {
    return axios.get('/api/pos/ListxUsuario', { params })
  },
  ListPosPanel (params) {
    return axios.get('/api/pos/ListPosPanel', { params })
  },
  ActualizarPrecio (params) {
    return axios.put('/api/pos/ActualizarPrecio', params)
  },
  SendMQTTMessage (params) {
    return axios.post('/api/pos/SendMQTTMessage', params)
  }
}
